import { 
  USERS_LIST_REQUEST, USERS_LIST_REQUEST_SUCCESS,USERS_LIST_REQUEST_FAIL,
 } from "../Constants/usersListConstant";

function  usersListReducer(state = {usersData: []}, action) {
  switch (action.type) {
    case USERS_LIST_REQUEST:
      return { loading: true, usersData: null };
    case USERS_LIST_REQUEST_SUCCESS:
      return { loading: false, usersData: action.payload };
    case USERS_LIST_REQUEST_FAIL:
      return { loading: false, error: action.payload };
    default: return state;
  }
}

export {
  usersListReducer
}