import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Image, Layout, Typography, Tooltip } from 'antd';
import { SettingOutlined, MailOutlined, BulbOutlined, LogoutOutlined, BarChartOutlined, LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Auth } from "aws-amplify";
import { USER_MAILBOXES, USER_INSIGHTS,USER_SETTINGS, USER_CONTENT_TESTS, USER_EDIT_CONTENT_TESTS, USER_CREATE_CONTENT_TESTS, USER_SENDING_PATTERN, ADMIN_USERS, ADMIN_USERS_DETAILS_ID  } from '../Routes/Constants/RouteUrls';
import LabIcon from '../assets/images/sidebar/LabIcon';
import { getlocalStorageValue } from '../Helpers/globalValues'
import { mailboxDomainListsAction } from '../Store/Actions/mailboxActions';
import { scrollToTop } from '../Helpers/WindowHelper';

const SiderLayout = () => {
    const [openKeys, setOpenKeys] = useState([]);
    const { Text } = Typography;
    const [showSendingPattern, setShowSendingPattern] = useState(false);
    const { mailboxDomainLists } = useSelector((state) => state.mailboxDomainListsInfo);
    const { demoData, userId, companyId } = getlocalStorageValue(['demoData','userId','companyId'])
    const history = useHistory();
    const dispatch = useDispatch();
    const { SubMenu } = Menu;
    const sendingPattern = (type) => {
        if(type==='open'){
            setShowSendingPattern(true);
            setOpenKeys(['sub1'])
            setloader(locationKey)
        }else {
            setShowSendingPattern(false);
        }
    }

    const handleLogout = async () => {
        localStorage.clear();
        await Auth.signOut({ global: true });
        history.push("/");
    }
    const [loader, setloader] = useState('1');
    const location = useLocation();
    let locationKey='1';
    if(location){
        switch (location.pathname){
            case ADMIN_USERS:
            case ADMIN_USERS_DETAILS_ID:
                    locationKey ='11';
                    break;

            default:
                locationKey ='11';
                // do nothing
        }
        if(loader!==locationKey)
            setloader(locationKey)
    }

    useEffect(() => {
        
        (!mailboxDomainLists && !mailboxDomainLists?.length)
            && dispatch(mailboxDomainListsAction(userId));

        location?.pathname?.includes(USER_SENDING_PATTERN) && sendingPattern('open')
    }, [])

   
    const planDetails = useSelector((state) => state.planDetails);
    const { planData, 
        /* loading, error */
    } = planDetails;
    const authResult = JSON.parse(localStorage.getItem('AuthenticationResult'));
    if(!userId){
        handleLogout();
    }
    const planPermission = JSON.parse(planData?.planData?.records[0]?.plan_policy ?? '{}');
    const contentTestsFlag = planPermission?.contentTests

    useEffect(() => {
        if(loader===5) {
            (!demoData || !contentTestsFlag) && history.push(USER_MAILBOXES);
        }
    }, [loader])
    useEffect(() => { scrollToTop(); } ,[location.pathname]);

    const {Sider} = Layout;
    return (
        <Sider theme="white" className="h-screen fixed">
            <div className="logo ns-menu" style={{margin:"20px 25px 11px 14px"}}>
                <Image width={135} height={40}  preview={false} src="/images/logo_allegrow.svg"></Image>
            </div>
            <div className="static mt-20" >
                    {
                        !showSendingPattern
                        ?   <div className="">
                               <Menu  subMenuOpenDelay={2} subMenuCloseDelay={1} theme="white"  className="v-align-3 ns-menu-10 h-screen" mode={`${'vertical'}`} selectedKeys={loader}> 
                                    <Menu.Item key="11"  icon={<UserOutlined />}>
                                        <Link to={ADMIN_USERS} >Users</Link>
                                    </Menu.Item>
                                    {/* <Menu.Item key="1"  icon={<MailOutlined />}>
                                        <Link to={USER_MAILBOXES} >Performance</Link>
                                    </Menu.Item>
                                    <Menu.Item key="7"  icon={<BarChartOutlined />} >
                                        <Link to="#" onClick={()=>sendingPattern('open')} >Sending Pattern</Link>
                                    </Menu.Item>
                                    
                                    { (demoData || contentTestsFlag) &&
                                        <Menu.Item key="5"  icon={<LabIcon/>}>
                                            <Link to={USER_CONTENT_TESTS} >Content Tests</Link>
                                        </Menu.Item>
                                    }
                                    <Menu.Item key="2"  icon={<BulbOutlined />}>
                                        <Link to={USER_INSIGHTS} >Insights</Link>
                                    </Menu.Item>
                                    <Menu.Item key="3" className="ns-absolute bottom-0 w-full" style={{marginBottom:'45px'}} icon={<SettingOutlined />}>
                                        <Link to={USER_SETTINGS}>Settings</Link>
                                    </Menu.Item>*/}
                                    <Menu.Item key="4" className="ns-absolute bottom-0 w-full"   icon={<LogoutOutlined />}>
                                        <Link to="#" onClick={handleLogout}>Logout</Link>
                                    </Menu.Item> 
                               </Menu>
                            </div>
                        :
                            <Menu className="v-align-2 ns-menu-5" mode={`${'inline'}`} selectedKeys={loader} expandIcon={'  '}  subMenuOpenDelay={20} subMenuCloseDelay={10} openKeys={openKeys} theme="white">
                                <SubMenu className="ns-menu-5" onTitleClick={()=>sendingPattern('close')} icon={<LeftOutlined />} title="Sending Pattern"  key="sub1">
                                        {   
                                            !!(mailboxDomainLists && mailboxDomainLists?.length)
                                            ?  
                                                mailboxDomainLists?.map((domain) => {
                                                    return (
                                                        <Menu.ItemGroup className="ns-sub-menu" key={domain.domain_name} title={<Tooltip mouseEnterDelay={1}  placement="right" title={domain.domain_name}><Text className="text-gray-500 w-full"  ellipsis={true}>{domain.domain_name}</Text></Tooltip>}>
                                                            {domain.sub_accounts.map((emailAccount,emailIndex) =>  <Menu.Item className="domain-email-ellipsis" key={emailAccount.id}><Tooltip mouseEnterDelay={1} className=""  placement="left" title={emailAccount.email}><Link className="" to={USER_SENDING_PATTERN+emailAccount.id}><Text className="w-full text-gray-500"  ellipsis={true}><span className="text-black">{emailAccount.email.split('@')[0]}</span>@{emailAccount.email.split('@')[1]}</Text></Link></Tooltip></Menu.Item>)}
                                                        </Menu.ItemGroup>
                                                    )
                                                })
                                            : <Menu.ItemGroup className="ns-sub-menu" key={444} title={'No connections are available'}></Menu.ItemGroup>
                                        }
                                </SubMenu>
                            </Menu>
                    }          
                
            </div>
      </Sider>
    )
}

export default SiderLayout;