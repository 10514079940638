import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { loadState, saveState } from './localStorage';
import { throttle } from 'lodash';

import { mailboxIdStatisticsReducer, mailboxDetailsUpdateReducer, mailboxConnectReducer, mailboxDomainListsReducer, mailboxDomainStatisticsReducer, mailboxValidateReducer } from './Reducers/mailboxReducers';
import { outgoingMailboxDailyFutureLimitReducer, outgoingMailboxDailyFutureLimitSaveReducer, outgoingMailboxExpectedEmailsReducer } from './Reducers/outgoingMailboxReducers';
import { companyDetailsReducer, companyDetailsSaveReducer } from './Reducers/companyDetailsReducers';
import { planDetailsReducer, planAllDetailsReducer } from './Reducers/planDetailsReducers';
import { userInsightsReducer, mailboxInsightsReducer, archivedInsightsReducer, domainInsightsCountReducer } from './Reducers/insightsReducers';
import { contentTestsReducer, contentTestsCreateUpdateReducer, contentTestsDraftReducer, contentTestsDeleteReducer, contentTestsFinishReducer } from './Reducers/contentTestsReducers';
import { usersListReducer } from './Reducers/usersListReducers';

import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
} from './Reducers/userReducers';


const reducer = combineReducers({
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  
  mailboxIdStatistics: mailboxIdStatisticsReducer,
  mailboxDetailsUpdate:mailboxDetailsUpdateReducer,
  mailboxConnectInfo:mailboxConnectReducer,
  mailboxDomainListsInfo:mailboxDomainListsReducer,
  mailboxDomainStatisticsInfo:mailboxDomainStatisticsReducer,
  mailboxValidateInfo:mailboxValidateReducer,
  
  outgoingMailboxDailyFutureLimit:  outgoingMailboxDailyFutureLimitReducer,
  outgoingMailboxDailyFutureLimitSave:  outgoingMailboxDailyFutureLimitSaveReducer,
  outgoingMailboxExpectedEmails:  outgoingMailboxExpectedEmailsReducer,
  
  companyDetails:companyDetailsReducer,
  companyDetailsSave:companyDetailsSaveReducer,
  
  planDetails:planDetailsReducer,
  planAllDetails:planAllDetailsReducer,
  
  userInsightsDetails:userInsightsReducer,
  mailboxInsightsDetails:mailboxInsightsReducer,
  archivedInsightsDetails:archivedInsightsReducer,
  domainInsightsCountDetails:domainInsightsCountReducer,

  contentTestsInfo:contentTestsReducer,
  contentTestsCreateUpdateInfo:contentTestsCreateUpdateReducer,
  contentTestsDraftInfo:contentTestsDraftReducer,
  contentTestsDeleteInfo:contentTestsDeleteReducer,
  contentTestsFinishInfo:contentTestsFinishReducer,
  
  usersListInfo:usersListReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(
  reducer,
  persistedState,
  composeEnhancer(applyMiddleware(thunk)),
);

store.subscribe(throttle(() => {
  saveState({
    planDetails: store.getState().planDetails
  });
}, 1000));

export default store;
