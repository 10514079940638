import AxiosApi from '../../config/axios-api';
import {
  MAILBOX_ID_STATISTICS_REQUEST,MAILBOX_ID_STATISTICS_REQUEST_SUCCESS,MAILBOX_ID_STATISTICS_REQUEST_FAIL,
  MAILBOX_UPDATE_REQUEST,MAILBOX_UPDATE_SUCCESS,MAILBOX_UPDATE_FAIL,
  MAILBOX_CONNECT_REQUEST,MAILBOX_CONNECT_SUCCESS,MAILBOX_CONNECT_FAIL,
  MAILBOX_DOMAIN_LISTS_REQUEST, MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS, MAILBOX_DOMAIN_LISTS_REQUEST_FAIL,
  MAILBOX_DOMAIN_STATISTICS_REQUEST, MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS, MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL,
  MAILBOX_VALIDATE_REQUEST, MAILBOX_VALIDATE_SUCCESS, MAILBOX_VALIDATE_FAIL,
} from "../Constants/maillboxConstants";

const mailboxIdStatisticsAction = (mailboxId) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST, payload: mailboxId });
    const { data } = await AxiosApi.get('analytics/user-mailbox-statistics/' + mailboxId);
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_ID_STATISTICS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxDomainListsAction = (userId,queryParams='') => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST, payload: userId });
    const { data } = await AxiosApi.get('mailboxes/list-user-mailboxes/' + userId+queryParams);
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_DOMAIN_LISTS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxDomainStatisticsAction = (userId,queryParams='') => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST, payload: userId });
    const { data } = await AxiosApi.get('analytics/user-domains-statistics/' + userId+queryParams);
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST_SUCCESS, payload: data.result });
  } catch (error) {
    dispatch({ type: MAILBOX_DOMAIN_STATISTICS_REQUEST_FAIL, payload: error.message });
  }
};

const mailboxDetailsUpdateAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_UPDATE_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/update-mailbox-details',mailboxData);
    dispatch({ type: MAILBOX_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_UPDATE_FAIL, payload: error.message || error.errorMessage });
    throw error.errorMessage;
  }
};

const mailboxConnectAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_CONNECT_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/connect-new-mailbox',mailboxData);
    dispatch({ type: MAILBOX_CONNECT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_CONNECT_FAIL, payload: error });
  }
};

const mailboxValidateAction = (mailboxData) => async (dispatch) => {
  try {
    dispatch({ type: MAILBOX_VALIDATE_REQUEST, payload: mailboxData });
    AxiosApi.defaults.headers.post['Content-Type'] = 'application/json';
    const { data } = await AxiosApi.post('mailboxes/validate-mailbox-credentials',mailboxData);
    dispatch({ type:  MAILBOX_VALIDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: MAILBOX_VALIDATE_FAIL, payload: error.message || error.errorMessage });
    throw error.errorMessage;
  }
};

export { 
  mailboxIdStatisticsAction, mailboxDetailsUpdateAction, mailboxConnectAction, mailboxDomainListsAction, mailboxDomainStatisticsAction,
  mailboxValidateAction,
};