import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { Layout,  Spin } from 'antd';
import SiderLayout from '../Layout/SiderLayout';
import ProtectedRoutes from './ProtectedRoutes';

const ProtectedUserRoute = (props) => {
  const userId = localStorage.getItem("userId")
  const loading = <Spin className="p-56" size="large" tip="Loading..." spinning={true} />;
  return (
          <>  
          {
            userId 
            ? (
                <Layout className="1xl:h-screen">
                  <SiderLayout/>
                  <Layout className="static">
                    <div className="ns-sider-min-height 1xl:h-screen h-full">
                      <React.Suspense fallback={loading}>
                        <Switch>
                          {ProtectedRoutes.map((route, idx) => {
                            return route.component && 
                              <Route key={idx} path={route.path} exact={route.exact} component={route.component} />
                          })}
                        </Switch>
                      </React.Suspense>
                    </div>
                  </Layout>
                </Layout>
              )
            :<Redirect to="/" />
          }
         </>
    )
}

export default ProtectedUserRoute